<template>
	<e-select
		track-by="horse_id"
		label="horse_nom"
		v-model="searchValue"
		:options="options"
		:placeholder="$t('global.ecrire_pour_rechercher')"
		:loading="isLoading"
		:sortable="false"
		:internal-search="false"
		@search-change="onSearch"
		@select="searchJument"
		ref="input"
	>
		<template slot="noOptions">{{ $t('global.write_to_search') }}</template>
		<template slot="noResult">{{ $t('global.no_result') }}</template>
	</e-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import Navigation from '@/mixins/Navigation'

export default {
	name: 'SearchJument',
	mixins: [Navigation],
	props: ['preselected','addHorse', 'juments'],
    data() {
        return {
			options: [],
			searchValue: null,
			isLoading: false,
			debouncedSearch: _debounce(this.search, 300),
			actual_search:'',
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			if(this.preselected) {
				this.options = [this.preselected]
				this.searchValue = this.preselected
			}
		},
		async onHorseId(selectedHorse_id){
			this.options = []
			if(this.$storage.db.horse) {
				let horse = await this.$storage.db.t('horse').then(table => {
					return table.where({horse_sexe: 'F', horse_id: selectedHorse_id})
				}).then(col => {
					return col.toArray()
				})
				if(horse){
					this.options= horse
					this.searchValue = horse[0]
					this.$emit("stopFocus")
				} else {
					this.options= []
					this.searchValue = ''
				}
			}
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			this.isLoading = true

			if(search_val == '') {
				this.isLoading = false
				this.options = []
				return false
			}

			if(this.$storage.db.horse) {
				let horse = await this.$storage.db.t('horse').then(table => {
					return table.where({horse_sexe: 'F', horse_inactive: 0})
				})
				.then(col => {
					return col.filter(elem => {
						var regex = new RegExp(search_val, 'gi');
						return regex.test(elem.horse_nom) || regex.test(elem.horse_sire) || regex.test(elem.horse_transpondeur)
					})
				})
				.then(col => {
					return col.limit(150).toArray()
				})
				this.options = horse.sortBy('horse_nom')
				if(this.options.length === 0 && this.addHorse){
					this.options.push({horse_id: -1, horse_nom: this.getTrad("horse.add_horse")})
				}
				this.actual_search = search_val
			}

			this.isLoading = false
		},
		onFocus() {
			this.$refs.input.activate()
			this.$refs.input.$refs.search.click()
		},
		async searchJument(option) {
			if(option.horse_id === -1){
				this.$emit("addHorseVal", this.actual_search)
				this.options = []
			} else {
				this.searchValue = option.horse_nom
				this.$emit("update:horse_selected", option)
			}
		},
	},
	watch: {
		'preselected' (val) {
			this.options = [val]
			this.searchValue = val
		},
		juments(val) {
			this.options = val
		}
	}
}
</script>
